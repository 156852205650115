export const dataTokenNomic = [
  {
    image: "assets/frogpepe-avt.png",
    subTitle: "TOTAL SUPPLY",
    percent: "1B",
  },
  {
    image: "assets/frogpepe-web1.png",
    subTitle: "LP burned",
    percent: "100%",
  },
  {
    image: "assets/frogpepe-web2.png",
    subTitle: "Tax",
    percent: "0%",
  },
];
