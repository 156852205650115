import React from 'react';

type Props = {};

const BuyHere = (props: Props) => {
  return (
    <div className="w-full max-w-[1350px] mx-auto mt-6 mb-4 px-4 sm:px-6 lg:px-8">
      <div className="w-full text-center">
        <p className="text-white font-black text-[24px] sm:text-[32px] md:text-[40px] lg:text-[48px] pt-4 break-all">CA:32xJh4BkrZg3LdB8J5p2E5U1dJBbbBgi9iBRxxaMN8U5</p>
      </div>
      <div className="flex flex-col lg:flex-row justify-between items-center mt-6">
        <div className="w-full lg:w-[32%] flex justify-end mb-6 lg:mb-0">
          <a href="https://dexscreener.com/solana" target="_blank" rel="noopener noreferrer" className="bg-dark-green text-white w-full lg:w-[430px] h-20 lg:h-24 underline text-center text-[40px] lg:text-[80px] leading-[44px] rounded-[10px] font-black px-3 lg:translate-y-[105px] py-4">Buy here</a>
        </div>
        <div className="w-full lg:w-[32%] flex justify-center mb-6 lg:mb-0">
          <img src="assets/frogpepe-web.png" className="w-[280px] h-[280px] sm:w-[340px] sm:h-[340px] md:w-[380px] md:h-[380px] lg:w-[420px] lg:h-[420px]" alt="Frog Pepe" />
        </div>
        <div className="w-full lg:w-[32%] flex justify-start mb-6 lg:mb-0">
          <a href="/" className="bg-dark-green text-white w-full lg:w-auto text-center text-2xl sm:text-3xl md:text-4xl lg:text-5xl rounded-[10px] font-black px-3 lg:translate-y-[105px] py-4">Take a selfie with FROGPEPE</a>
        </div>
      </div>
      <div className="flex justify-center items-center gap-4 sm:gap-8 lg:gap-14 mt-12">
        <a href="https://dexscreener.com/solana" target="_blank" rel="noopener noreferrer">
          <img src="assets/logo0.webp" alt="Logo 0" className="w-[60px] sm:w-[80px] md:w-[100px] lg:w-[107px]" />
        </a>
        <a href="https://x.com/FROGPEPESOL" target="_blank" rel="noopener noreferrer">
          <img src="assets/logo-X.png" alt="Logo X" className="w-[60px] sm:w-[80px] md:w-[100px] lg:w-[107px] rounded-full" />
        </a>
        <a href="https://t.me/FROGPEPESOL" target="_blank" rel="noopener noreferrer">
          <img src="assets/logo-tele.png" alt="Logo Telegram" className="w-[60px] sm:w-[80px] md:w-[100px] lg:w-[107px] rounded-full" />
        </a>
      </div>
    </div>
  );
};

export default BuyHere;