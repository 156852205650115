import React from 'react'
import FortuneBebe from '../../components/HomePage/Fortunebebe'
import BuyHere from '../../components/HomePage/Buyhere'
import AboutUs from '../../components/HomePage/AboutUs'
import Tokenomics from '../../components/Tokenomics'
import { dataTokenNomic } from '../../data/Tokenomics.data'

type Props = {}

const HomePage = (props: Props) => {
  return (
    <div className='w-full bg-light-green'>
        <FortuneBebe />
        <BuyHere />
        <AboutUs />
        <Tokenomics listTokeNoMics={dataTokenNomic} title='Tokenomics' />
    </div>
  )
}

export default HomePage