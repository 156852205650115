import React from "react";

type Props = {};

const AboutUs = (props: Props) => {
  return (
    <div className="w-full mt-16 lg:mt-32 px-4 sm:px-6 lg:px-8">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center">
          <h1 className="text-4xl sm:text-6xl lg:text-8xl font-black text-white mb-6 lg:mb-0 pl-3">About</h1>
          <div className="flex w-full lg:w-auto justify-center lg:justify-end">
            <div className="bg-dark-green min-w-full lg:min-w-[500px] flex flex-col lg:flex-row justify-between items-center relative rounded-xl pl-7 py-4 lg:py-0">
              <div className="text-center text-white py-3 lg:py-0">
                <p className="font-black text-2xl sm:text-3xl lg:text-4xl">Token name :</p>
                <p className="font-black text-2xl sm:text-3xl lg:text-4xl text-white">PROGPEPE</p>
              </div>
              <img
                src="assets/frogpepe-web.png"
                className="w-20 h-20 sm:w-[88px] sm:h-[88px] lg:w-[110px] lg:h-[108px] absolute lg:static right-0 lg:right-[50px] top-[20px]"
                alt="FrogPePe"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row justify-between items-center mt-6 gap-6">
          <div className="w-full lg:w-[430px] h-auto lg:h-[400px]">
            <img
              src="assets/frogpepe-avt.png"
              className="w-full h-full border-[8px] border-beige-brown rounded-2xl object-cover"
              alt="FrogPePe Avatar"
            />
          </div>
          <div className="text-light-green bg-dark-green w-full lg:w-[730px] h-auto lg:h-[400px] rounded-2xl p-6 lg:p-4">
            <p className="text-center text-xl sm:text-2xl lg:text-[33px] text-white font-black px-2">
              Leap into innovation with FrogPePe, the token that’s revolutionizing the meme economy. Every trade not only fuels market excitement but also supports our green, amphibious mission. Don’t just invest—make a splash with FrogPePe and be part of a movement that’s as dynamic as it is profitable.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};


export default AboutUs;
