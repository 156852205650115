import React from "react";
import { IfTokenNomics } from "../../types/tokenomics.type";

type Props = {
  title: string;
  listTokeNoMics: IfTokenNomics[];
};

const Tokenomics: React.FC<Props> = ({ listTokeNoMics, title }) => {
  return (
    <div className="w-full mt-16 sm:mt-24 md:mt-32">
      <div className="container mx-auto px-4">
        <div className="text-center">
          <p className="font-black text-white text-3xl sm:text-5xl md:text-7xl lg:text-9xl">{title}</p>
        </div>
        <div className="flex flex-wrap justify-around mt-8 sm:mt-10">
          {listTokeNoMics.map((itemList, index) => (
            <div
              key={index}
              className="flex flex-col items-center gap-4 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-4"
            >
              <img
                src={itemList.image}
                className="w-24 h-24 sm:w-32 sm:h-32 md:w-40 md:h-40 lg:w-48 lg:h-48 rounded-full"
                alt={itemList.subTitle}
              />
              <h3 className="text-white font-black text-xl sm:text-2xl md:text-3xl lg:text-4xl">{itemList.subTitle}</h3>
              <p className="text-white font-black text-2xl sm:text-3xl md:text-4xl lg:text-5xl">{itemList.percent}</p>
            </div>
          ))}
        </div>
        <div className="text-center mt-12 sm:mt-16 md:mt-20">
          <p className="font-normal text-black text-sm sm:text-base md:text-lg">©2024 PROGPEPE, All rights reserved</p>
        </div>
      </div>
    </div>
  );
};

export default Tokenomics;