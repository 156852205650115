import React from "react";

type Props = {};

const FortuneBebe = (props: Props) => {
  return (
    <div className="w-full">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="w-full fortune flex flex-col items-center">
          <img
            src="assets/frogpepe-avt.png"
            alt="Frog Pepe"
            className="w-32 sm:w-48 md:w-64 lg:w-80 xl:w-96"
          />
          <h3 className="text-white hidden mt-4 text-xl sm:text-2xl md:block md:text-3xl lg:text-4xl xl:text-5xl">
            PROGPEPE
          </h3>
          <div className="text-center py-6 sm:py-9">
            <p className="text-white text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-black">
              $FROGPEPE
            </p>
          </div>
          <div className="w-full bg-[#295939] text-center rounded-2xl mt-6 px-4 py-4 sm:py-6 lg:py-8">
            <p className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-white font-black">
              Fortune is our PROGPEPE
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FortuneBebe;
